<template>
  <div>
    <b-tabs
      lazy
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab active :title="lang('t_integrations')">
        <integrations></integrations>
      </b-tab>
      <b-tab :title="lang('t_providers')">
        <providers></providers>
      </b-tab>
      <b-tab :title="lang('t_mVoice')">
        <voice></voice>
      </b-tab>

      <!-- <b-tab :title="lang('t_backup')">
        <backup></backup>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BContainer, BFormRadioGroup, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BButton, BTabs, BTab } from 'bootstrap-vue'
import General from './General/General.vue'
import Voice from './Voice/VoiceMain.vue'
import Backup from './Backup/Backup.vue'
import Providers from './Providers/Main.vue'
// import ServiceManagement from './ServiceManagement/ServiceManagement.vue'
import TenantManagement from './TenantManagement/TenantManagement.vue'
import Integrations from './Integrations/list/List.vue'
// import Projects from './Projects/list/List.vue'

export default {
  data() {

    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'general',
    }
  },
  components: {
    BFormInput,
    BContainer,
    Integrations,
    Voice,
    General,
    Backup,
    Providers,
    // ServiceManagement,
    TenantManagement,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BTabs,
    BTab
  },
}
</script>

<style>
</style>
