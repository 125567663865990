<template>
  <b-container class="px-0 fluid">
    <b-row class="mx-0">
      <b-card class="w-100" :sub-title="lang('t_loginPageImages')">
        <b-form-file
          @change="onFileChange"
          :placeholder="lang('t_pleaseSelectTheFileToUpload')"
          :browse-text="lang('t_browse')"
          multiple
          accept=".jpg, .png, .gif"
        ></b-form-file>
        <b-img
          class="mt-1 px-25"
          v-for="(img, i) in url"
          :key="i"
          width="256"
          height="256"
          :v-if="url.length > 0"
          :src="img"
        ></b-img>
        <b-button class="mt-1 float-right" variant="primary">{{
          lang("t_save")
        }}</b-button>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BProgress,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BFormInput,
  BFormFile,
  BImg
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BProgress,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormInput, BFormFile
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      popupActive: false,
      url: [],
    }
  },
  methods: {
    onFileChange(e) {
      console.log(e);
      this.url = [];
      for (const file of e.target.files) {
        this.url.push(URL.createObjectURL(file));
      }
    },
    confirmText(type) {
      this.$swal({
        title: this.lang('t_areYouSure'),
        text: this.lang('t_restartService'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          var response = (
            this.$http.post(`/ServiceRestart`, { service_name: type }, {
              headers: {
                authorization: globalThis.token,
              },
            })
          );
          console.log("restart_response:", response);
          if (response != null || response != undefined) {
            this.$swal({
              icon: 'success',
              title: this.lang('t_processSuccess'),
              text: this.lang('t_restartServiceSuccessfully'),
              confirmButtonText: this.lang('t_ok'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        }

      })
    },
  }
}

</script>

<style>
</style>
