<template>
  <b-container class="px-0 fluid">
    <b-row class="mx-0">
      <b-col>
      <b-form-input :placeholder="lang('t_tenantName')"></b-form-input>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BProgress,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BFormInput
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BProgress,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormInput
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      popupActive: false,
    }
  },
  methods: {
    confirmText(type) {
      this.$swal({
        title: this.lang('t_areYouSure'),
        text: this.lang('t_restartService'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          var response = (
            this.$http.post(`/ServiceRestart`, { service_name: type }, {
              headers: {
                authorization: globalThis.token,
              },
            })
          );
          console.log("restart_response:", response);
          if (response != null || response != undefined) {
            this.$swal({
              icon: 'success',
              title: this.lang('t_processSuccess'),
              text: this.lang('t_restartServiceSuccessfully'),
              confirmButtonText: this.lang('t_ok'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        }

      })
    },
  }
}

</script>

<style>
</style>
