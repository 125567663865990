<template>
  <div>
    <b-card-actions>
        <b-row class="mx-0">
          <b-col cols="12" class="px-0">
            <div class="mx-auto" max-width="100%">
              <b-card>
                <b-row>
                  <b-col class="pb-0" cols="12">
                    <b-row >
                      <b-col cols="12">
                        <!-- Operatör -->
                        <b-form-group>
                          <v-select
                            v-model="filter_values.selectedBackupFiles"
                            :options="files"
                            :placeholder="lang('t_backedupFiles')"
                          >
                          </v-select>
                        </b-form-group>
                        <!-- Proje -->
                        <b-form-group>
                          <b-form-file
                            :placeholder="lang('t_pleaseSelectTheFileToUpload')"
                            :drop-placeholder="lang('t_uploadFile')"
                            no-drop
                          />
                        </b-form-group>
                        <!-- Butonlar -->
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="success"
                          style="float: right"
                        >
                        {{lang("t_createBackup")}}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="gradient-primary"
                          style="float: right; margin-right:10px;"
                          class="ml-1"
                        >
                        {{lang("t_exportToDatabase")}}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
        </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BCardBody,BCardTitle,BCardHeader,BBadge, BCard, BCardText, BLink, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton, BFormFile } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BButton,
    BFormFile,
    BCardHeader,
    BCardTitle,
    BCardBody,
    vSelect
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      filter_values: {
      selectedBackupFiles: [],
    },
      files: [],
    }
  },
}

</script>

<style>
</style>
