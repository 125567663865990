<template>
<b-card-actions>
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <div class="mx-auto" max-width="100%">
              <b-card>
                <b-row>
                  <b-col class="pb-0" cols="12">
                    <b-row>
                      <v-snackbar
                        v-model="snackbar"
                        :timeout="10000"
                        :color="snackbarColor"
                        class="d-none"
                      >
                        {{ snackbarMessage }}
                        <b-button color="black" text @click="snackbar = false">{{
                          lang("t_close")
                        }}</b-button>
                      </v-snackbar>
                      <b-col class="pt-0">
                        <b-row>
                          <b-col cols="6">
                            <flat-pickr
                              v-model="inlineDate"
                              class="form-control"
                              :config="{ inline: true }"
                            />
                          </b-col>
                          <b-col cols="6">
                            <flat-pickr
                              v-model="inlineDate2"
                              class="form-control"
                              :config="{ inline: true }"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <v-select
                              class="mt-1 removevoice_select"
                              v-model="filter_values.selectedQueues"
                              :options="queues"
                              :placeholder="lang('t_queue')"
                              multiple
                            >
                            </v-select>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <v-select
                              class="mt-1 removevoice_select"
                              v-model="filter_values.selectedAgents"
                              :options="users"
                              :placeholder="lang('t_agents')"
                              multiple
                            >
                            </v-select>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1">
                            <b-button
                              @click="open_customer"
                              style="float: right"
                              class="btn-success"
                              >{{ lang("t_deleteCallRecordings") }}
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-card-text>
</b-card-actions>
  
</template>
<script>
import { BButton, BCard, BCardText, BLink, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  props: {
    project: '',
  },

  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    filter_values: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      selectedAgents: [],
      selectedQueues: [],
    },
    users: ['123', "asd", "vxczv"],
    queues: ['zxc', "123", "4124"],
    campaigns: [],
    open_customer_info: {
      campaign: ''
    },
    inlineDate: null,
    inlineDate2: null,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
  }),
  /*
    methods: {
      GetUsers: async function () {
        var response = (
          await this.$http.get(`/User`, {
            headers: {
              authorization: this.$store.state.user.token,
            },
          })
        ).data;
        this.users = response;

        console.log("project_users", this.users);
      },
      GetQueues: async function () {
        var response = (
          await this.$http.get(`/Queue`, {
            headers: {
              authorization: this.$store.state.user.token,
            },
          })
        ).data;
        console.log(response);
        this.queues = response;
      },
      open_customer: async function () {
        if (
          this.open_customer_info.campaign.length == 0
        ) {
          this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
          this.snackbarColor = 'warning';
          this.snackbar = true;
          return;
        }
        console.log("open_customer_info", this.open_customer_info);
        var response = (
          await this.$http.post(
            `/RemoveVoiceRecord`,
            {
              collection: `${this.project}_customers`,
              filter: this.filter_values
            },
            {
              headers: {
                authorization: this.$store.state.user.token,
              },
            }
          )
        ).data;

        if (response.ok != 1) {
          this.snackbarMessage = `Data açma yapılırken bir sorun oluştu.`;
          this.snackbarColor = 'error';
          this.snackbar = true;
        } else {
          this.snackbarMessage = `${response.matchedCount} adet data başarılı bir şekilde açıldı.`;
          this.snackbarColor = 'success';
          this.snackbar = true;
        }
        console.log('open_customer', response);
      },
    },
    computed: {},
     mounted: function () {
      this.GetUsers();
      this.GetQueues();
    },
  */

};
/*
import { Turkish } from "@core/lang/flatpickr_tr.js"
flatPickr(myElem, {
    "locale": Turkish // locale for this instance only
  });
  */
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
